import { defineStore } from 'pinia'
export const useInfoStore = defineStore('userInfo', {
  state: () => {
    return {
      usrName: ''  , //已经登录用户名
      user_id:'',
      level:''
    }
  },
  persist: true,
   })






  



